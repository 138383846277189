import React, { useContext, Suspense, lazy } from "react";
import { Routes as Router, Route, Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./providers/auth-provider";
import Login from "./pages/login/login";
import Home from "./pages/home/home";
import AppLayout from "./components/layout/layout";
import AddCompany from "./pages/companies/add-company/add-company";
import UpdateCompany from "./pages/companies/update-company/update-company";
import Loading from "./components/loading/loading";




const Companies = lazy(() => import("./pages/companies/companies"));




const PrivateRoutes = () => {
  const { authenticated } = useContext(AuthContext);
  let token = localStorage.getItem("token");

  if (!authenticated && !token) return <Navigate to={'/login'} replace />

  return <Outlet />
}


const Routes = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<AppLayout children={<Home />} />} />
          <Route path="/companies" element={<AppLayout children={<Companies />} />} />
          <Route path="/companies/add-company" element={<AppLayout children={<AddCompany />} />} />
          <Route path="/companies/update-company/:id" element={<AppLayout children={<UpdateCompany />} />} />
        </Route>
      </Router>
    </Suspense>
  )
}

export default Routes;
