import Routes from "./Routes";
import ToastProvider from "./providers/toast-provider";
import { AuthProvider } from "./providers/auth-provider";


function App() {
  return (
    <div className="bg-[#DBDBDB] h-dvh">
      <AuthProvider>
        <ToastProvider>
          <Routes />
        </ToastProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
