import { Toolbar, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { menuItemsAdmin, menuItemsWeb } from './sidebar-items';
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";

const Sidebar = () => {
  const allMenu = menuItemsAdmin.concat(menuItemsWeb);
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();



  const activeItem = (id: number): boolean => {
    const item = allMenu.find((item) => item.id === id);
    return item?.path === "/" + pathname.split("/")[1] ? true : false;
  }

  return (
    <div className='bg-[#373739] h-dvh'>
      <Toolbar >
        <div className='mb-5 p-1'>
          <img src={logo} alt="logo" className='h-2/3 w-2/3 mx-auto' />
        </div>
      </Toolbar>
      <Divider />
      <List>
        {menuItemsAdmin.map((item, index) => (
          <ListItem key={index} disablePadding
            className={activeItem(item.id) ? "bg-[#2AC1BE] text-white hover:bg-[#4ED0CB]" : "text-white hover:bg-[#4ED0CB]"}
          >
            <ListItemButton onClick={() => navigate(item.url)}>
              <ListItemIcon sx={{ color: "#fff" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider sx={{ border: "1px solid #fff" }} />
      <List>
        {menuItemsWeb.map((item, index) => (
          <ListItem key={index} disablePadding className={activeItem(item.id) ? "bg=[#2AC1BE] text-white" : "text-white hover:bg-[#4ED0CB]"}>
            <ListItemButton>
              <ListItemIcon sx={{ color: "#fff" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>)
}

export default Sidebar;

