import React from "react";
import { HomeOutlined, MapOutlined, PeopleOutlined, WorkOutlined, DirectionsCarOutlined, TimelineOutlined, VpnKeyOutlined, NotificationsOutlined, LinearScaleOutlined, PersonOutlined, DesignServices } from "@mui/icons-material";




interface MenuItemsTypes {
  id: number;
  name: string;
  path: string;
  url: string;
  icon: React.ReactNode;
}



export const menuItemsAdmin: MenuItemsTypes[] = [
  { id: 1, name: "Anasayfa", path: "/", url: "/", icon: <HomeOutlined /> },
  {
    id: 2,
    name: "Kullanıcılar",
    path: "/users",
    url: "/users?",
    icon: <PeopleOutlined />
  },

  {
    id: 3,
    name: "Yetkiler",
    path: "/permissions",
    url: "/permissions?",
    icon: <VpnKeyOutlined />,
  },

  {
    id: 4,
    name: "Firmalar",
    path: "/companies",
    url: "/companies?title=&contractType=&page=0&size=10",
    icon: <WorkOutlined />
  },

  {
    id: 5,
    name: "İstasyonlar",
    path: "/stations",
    url: "/stations?",
    icon: <MapOutlined />
  },

  {
    id: 6,
    name: "Araçlar",
    path: "/vehicles",
    url: "/vehicles?",
    icon: <DirectionsCarOutlined />
  },

  {
    id: 7,
    name: "İşlemler",
    path: "/transactions",
    url: "/transactions?",
    icon: <TimelineOutlined />,
  },

  {
    id: 8,
    name: "Bildirimler",
    path: "/notifications",
    url: "/notifications?",
    icon: <NotificationsOutlined />,
  },
];

export const menuItemsWeb: MenuItemsTypes[] = [
  {
    id: 9,
    name: "Sliders",
    path: "/slider",
    url: "/sliders?",
    icon: <LinearScaleOutlined />
  },
  {
    id: 10,
    name: "Hakkımızda",
    path: "/about-us",
    url: "/about-us?",
    icon: <PersonOutlined />
  },
  {
    id: 11,
    name: "Hizmetler",
    path: "/services",
    url: "/services?",
    icon: <DesignServices />
  },
];

