import { Field, Form, Formik } from "formik";
import FileInputField from "../../../components/formik-fields/file-input-field";
import SelectInputField from "../../../components/formik-fields/select-input-field";
import TextInputField from "../../../components/formik-fields/text-input-field";
import TexInputFieldWithIcon from "../../../components/formik-fields/text-input-field-with-icon";
import SubmitAndCancelButton from "../../../components/submit-cancel-buttons/submit-cancel-button";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { addCompany } from "../../../redux/features/companies/companiesThunks";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PromiseDelay } from "../../../utils/promise-delay";

interface RequestType {
  title: string;
  acUnitPrice: string;
  dcUnitPrice: string;
  contractType: string;
}

interface InitialStateTypes {
  request: RequestType;
  imageFile: any;
  contractFile: any;
}

const AddCompany = () => {
  const { status, error } = useAppSelector((state) => state.companies);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const initialState: InitialStateTypes = {
    request: {
      title: "",
      acUnitPrice: "",
      dcUnitPrice: "",
      contractType: "",
    },
    imageFile: null,
    contractFile: null,
  };

  const handleSubmit = async (values: InitialStateTypes) => {
    const jsonData = values.request;

    const json = JSON.stringify(jsonData);
    const blob = new Blob([json], { type: "application/json" });

    const formData = new FormData();
    formData.append("request", blob);
    formData.append("imageFile", values.imageFile);
    formData.append("imageFileEventType", "SAVED");
    formData.append("contractFile", values.contractFile);
    formData.append("contractFileEventType", "SAVED");

    const response = await dispatch(addCompany(formData));
    if (response.meta.requestStatus === "fulfilled") {
      toast.success("Firma başarılı bir şekilde kaydedildi");
      PromiseDelay();
      navigate(-1);
    } else {
      toast.error(error);
    }
  };
  return (
    <div className="rounded-xl bg-white p-5">
      <div className="text-[#666] font-bold text-sm">Firma Bilgileri</div>
      <Formik
        initialValues={initialState}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 py-5">
            <div>
              <TextInputField
                name="request.title"
                label="Firma Adı"
                required={true}
                error={false}
              />
            </div>

            <div>
              <Field
                name="imageFile"
                component={FileInputField}
                label="Firma Logosu"
                accepted=".png, .jpg, .jpeg"
                method="POST"
                required={true}
              />
            </div>

            <div>
              <TexInputFieldWithIcon
                name="request.acUnitPrice"
                label="AC Birim Fiyat"
                required={true}
                error={false}
              />
            </div>

            <div>
              <TexInputFieldWithIcon
                name="request.dcUnitPrice"
                label="DC Birim Fiyat"
                required={true}
                error={false}
              />
            </div>

            <div>
              <SelectInputField
                name="request.contractType"
                label="Anlaşma Durumu"
                required={true}
                error={false}
                items={[
                  { label: "Anlaşmalı", value: "CONTRACTUAL" },
                  { label: "Anlaşmasız", value: "WITHOUT_CONTRACT" },
                  { label: "Beklemede", value: "PENDING" },
                ]}
              />
            </div>

            <div>
              <Field
                name="contractFile"
                component={FileInputField}
                label="Sözleşme"
                accepted=".pdf"
                method="POST"
                required={true}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <SubmitAndCancelButton loading={status === "loading" ? true : false} />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default AddCompany;
