import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SubmitAndCancelButton = (props: { loading: boolean }) => {
  const navigate = useNavigate();
  return (
    <div className="flex gap-5">
      <button
        className="bg-[#DBDBDB] text-[#00A09D] py-2 px-6 rounded-xl"
        type="button"
        onClick={() => navigate(-1)}
        disabled={props.loading}
      >
        İPTAL
      </button>
      <button
        type="submit"
        className="bg-[#00A09D] text-white py-2 px-6 rounded-xl inline-flex justify-center items-center gap-3"
        disabled={props.loading}
      >
        KAYDET
        {props.loading && <CircularProgress size={16} color="inherit" />}
      </button>
    </div>
  );
};

export default SubmitAndCancelButton;
