import { TextField, InputAdornment } from "@mui/material";
import CurrencyLiraOutlinedIcon from '@mui/icons-material/CurrencyLiraOutlined';
import { Field, FieldProps } from "formik";


type PropsType = {
  name: string;
  label: string;
  required: boolean;
  error: boolean;
}

const TexInputFieldWithIcon = (props: PropsType) => {
  return (
    <>
      <Field name={props.name}>
        {({ field }: FieldProps) => (
          <TextField
            {...field}
            label={props.label}
            required={props.required}
            error={props.error}
            fullWidth
            size="small"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position='end'>
                    <CurrencyLiraOutlinedIcon />
                  </InputAdornment>
                )
              }
            }}
          />
        )}
      </Field>
    </>
  )
}

export default TexInputFieldWithIcon;
