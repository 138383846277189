import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";
import companiesSlice from "./features/companies/companiesSlice";
import userSlice from "./features/user/userSlice";
import deleteModalSlice from "./features/delete-modal/deleteModalSlice";


const store = configureStore({
  reducer: {
    "user": userSlice,
    "companies": companiesSlice,
    "deleteModal": deleteModalSlice,
  },
});


export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;





