import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

type InitialStateType = {
  show: boolean;
  title: string;
};

const initialState: InitialStateType = {
  show: false,
  title: "",
};

const deleteModalSlice = createSlice({
  name: "deleteModal",
  initialState,
  reducers: {
    toggleModal: (state, action: PayloadAction<InitialStateType>) => {
      state.show = action.payload.show;
      state.title = action.payload.title;
    },
  },
});

export default deleteModalSlice.reducer;
export const { toggleModal } = deleteModalSlice.actions;
