import React, { useState, useContext } from 'react';
import { useNavigate, Link } from "react-router-dom";
import logo from "../../assets/images/logo.png"
import imza from "../../assets/images/imza.png"
import { Form, Formik } from 'formik';
import TextInputField from '../../components/formik-fields/text-input-field';
import PasswordInputField from '../../components/formik-fields/password-input-field';
import axiosClient from '../../services/axiosInstance';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { AuthContext } from '../../providers/auth-provider';
import { useAppDispatch } from '../../redux/store';
import { initialize } from '../../redux/features/user/userThunks';

interface LoginStateType {
  username: string;
  password: string;
}

const Login = () => {
  const { setAuthenticated } = useContext(AuthContext)
  const dispatch = useAppDispatch();
  const apiClient = axiosClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);




  const initialState: LoginStateType = {
    username: "",
    password: "",
  }


  const handleLogin = async (values: LoginStateType) => {
    setLoading(true);
    try {
      const response = await apiClient.post("/authentications/sign-in", values);
      if (response.status === 200) {
        localStorage.setItem("token", response.data?.idToken);
        const init = await dispatch(initialize());
        if (init.meta.requestStatus === "fulfilled") {
          setAuthenticated(true);
          toast.success("Giriş işlemi başarılı");
          navigate("/");
        }
      }
    } catch (error) {
      toast.warning("Lütfen kullanıcı bilgilerinizi kontrol ediniz");
    }
    setLoading(false);
  }
  return (
    <div className='h-dvh grid grid-col-1 lg:grid-cols-3 p-8 bg-[#373739]'>
      <div className='col-span-1 lg:col-span-2 flex flex-col items-center justify-around'>
        <div className='flex-grow flex items-center'>
          <img src={logo} alt='logo' />
        </div>

        <div>
          <img src={imza} alt='imza' />
        </div>
      </div>

      <div className='rounded-2xl bg-white flex flex-col'>
        <div className='flex-1 flex flex-col justify-center items-center'>
          <div className='text-4xl font-bold'>Hoşgeldiniz</div>
          <div className='text-xs font-normal mt-2'>Lütfen bilgilerinizi giriniz</div>
        </div>

        <div className='flex-1 px-24'>
          <Formik initialValues={initialState} onSubmit={(values) => handleLogin(values)}>
            <Form>
              <div>
                <TextInputField
                  name='username'
                  label='E-posta'
                  required={true}
                  error={false}
                />
              </div>

              <div className='mt-5'>
                <PasswordInputField name='password' label='Parola' error={false} />
              </div>

              <div className='flex justify-between mt-5'>
                <div>
                  <input type='checkbox' />
                  <label className='text-xs text-[#666] ms-2'>Beni hatırla</label>
                </div>
                <Link to={"/forgot-password"} className='text-sm text-[#FF8A00] underline'>Şifremi unuttum</Link>
              </div>

              <div className='mt-5'>
                <button type='submit' className='text-white bg-[#2AC1BE] w-full p-3 rounded-lg hover:bg-[#4ED0CB]'>Giris Yap {loading && <CircularProgress size={18} color='inherit' />}</button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default Login;

