import { createSlice } from "@reduxjs/toolkit";
import { initialize } from "./userThunks";
import { UserResponseType } from "./types";



type InitialStateType = {
  data: UserResponseType | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string;
}

const initialState: InitialStateType = {
  data: null,
  status: "idle",
  error: "",
}


const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initialize.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(initialize.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = "succeeded";
    })
    builder.addCase(initialize.rejected, (state, action) => {
      state.data = null;
      state.status = "failed";
      state.error = action.error.message || "Kullanıcı bilgileri getirilirken bir hata oluştu";
    })
  }
})

export default userSlice.reducer;
