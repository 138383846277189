import { VisibilityOutlined } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FieldProps } from "formik";
import React, { useState } from "react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface PropsType extends FieldProps {
  label: string;
  accepted: string;
  method: "POST" | "PUT";
  required?: boolean;
  error?: boolean;
}

const FileInputField: React.FC<PropsType> = ({
  field,
  form,
  label,
  accepted,
  method,
  required,
  error,
}) => {
  const [file, setFile] = useState<File>();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    const file = files[0];
    setFile(file);
    form.setFieldValue(field.name, file);
  };
  return (
    <>
      <div className="flex gap-5">
        <Button
          sx={{ border: "1px solid #dbdbdb", height: "40px", flex: 1 }}
          component="label"
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          fullWidth
        >
          {file ? file.name : label}
          <VisuallyHiddenInput
            type="file"
            accept={accepted}
            required={required}
            onChange={handleInputChange}
          />
        </Button>

        {
          method === "PUT" && <a href={field.value?.url} target={"_blank"} className="border bg-orange-200 rounded flex gap-2 justify-center items-center flex-1">
            <VisibilityOutlined />
            <span>
              Önizle
            </span>
          </a>
        }
      </div>
    </>
  );
};

export default FileInputField;
