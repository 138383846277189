import React from "react";
import { ToastContainer, Bounce } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';



type PropType = {
  children: React.ReactNode;
}


export default function ToastProvider({ children }: PropType) {
  return (
    <>
      {children}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        pauseOnHover
        transition={Bounce}
      />
    </>
  )
}
