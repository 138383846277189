import { Box, Toolbar } from "@mui/material";
import Navbar from '../navbar/navbar';

const drawerWidth = 240;

type AppLayoutProp = {
  children: React.ReactNode;
}

const AppLayout = (props: AppLayoutProp) => {

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Navbar />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          {props.children}
        </Box>
      </Box>
    </>
  );
}

export default AppLayout;

