import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Field, FieldProps } from "formik";

type ItemType = {
  label: string;
  value: string;
};

type PropsType = {
  name: string;
  label: string;
  required: boolean;
  error: boolean;
  items: ItemType[];
};

const SelectInputField = (props: PropsType) => {
  return (
    <>
      <Field name={props.name}>
        {({ field }: FieldProps) => (
          <FormControl sx={{ minWidth: 120, flex: 1 }} size="small" fullWidth>
            <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
            <Select
              {...field}
              labelId="demo-simple-select-label"
              label={props.label}
              required={props.required}
              error={props.error}
            >
              {props.items.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Field>
    </>
  );
};

export default SelectInputField;
