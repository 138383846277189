import { CircularProgress } from '@mui/material'

const Loading = () => {
  return (
    <div className="h-dvh w-full flex justify-center items-center">
      <CircularProgress color='inherit' size={30} />
    </div>
  )
}

export default Loading;
