import { createAsyncThunk } from "@reduxjs/toolkit";
import { create } from "domain";
import axiosClient from "../../../services/axiosInstance";


const apiClient = axiosClient();

// title=${title}&contractType=${contractType}&
export const getAllCompanies = createAsyncThunk("api/getAllCompanies", async ({ title, contractType, page, size }: { title: string | null; contractType: string | null; page: string; size: string }) => {
  const response = await apiClient.get(`/companies?
    page=${page}&size=${size}`)
  return response.data;
});

export const getCompanyById = createAsyncThunk("api/getCompanyById", async (id: string) => {
  const response = await apiClient.get(`/companies/${id}`);
  return response.data;
});


export const addCompany = createAsyncThunk("api/addCompany", async (data: FormData) => {
  const response = await apiClient.post("/companies", data, { headers: { "Content-Type": "multipart/formdata" } })
  return response.data;
});


export const updateCompany = createAsyncThunk("api/updateCompany", async ({ id, data }: { id: string, data: FormData }) => {
  const response = await apiClient.put(`/companies/${id}`, data, { headers: { "Content-Type": "multipart/formdata" } });
  return response.data;
});

export const deleteCompany = createAsyncThunk("api/deleteCompany", async (id: string) => {
  const response = await apiClient.delete(`/companies/${id}`);
  return response.data;
});


