import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import React, { useState } from 'react'
import { Field, FieldProps } from 'formik';

interface PropsTypes {
  name: string;
  label: string;
  error: boolean;
}
const PasswordInputField = (props: PropsTypes) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
    <>

      <Field name={props.name}>
        {({ field }: FieldProps) => (
          <FormControl className='w-full'>
            <InputLabel>{props.label}</InputLabel>
            <OutlinedInput
              {...field}
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibilty'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                  </IconButton>
                </InputAdornment>
              }
              label={props.label}
              size='small'
              required
              error={props.error}
              fullWidth
            />
          </FormControl>)}
      </Field>

    </>
  )
}

export default PasswordInputField

