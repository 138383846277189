import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../../services/axiosInstance";



const apiClient = axiosClient();

export const initialize = createAsyncThunk("api/initialize", async () => {
  const response = await apiClient.post("accounts/initialize");
  return response.data;
});
