import { Field, FieldProps } from "formik";
import { TextField } from "@mui/material";

interface PropsTypes {
  name: string;
  label: string;
  required: boolean;
  error: boolean;
}

const TextInputField = (props: PropsTypes) => {
  return (
    <>
      <Field name={props.name}>
        {({ field }: FieldProps) => (
          <TextField
            {...field}
            label={props.label}
            required={props.required}
            error={props.error}
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
      </Field>
    </>
  );
};

export default TextInputField;
