import { createContext, ReactNode, useEffect, useState } from "react";
import { initialize } from "../redux/features/user/userThunks";
import { useAppDispatch } from "../redux/store";


type Props = {
  children?: ReactNode;
}


type IAuthContext = {
  authenticated: boolean;
  setAuthenticated: (newState: boolean) => void;
}


const initialValue = {
  authenticated: false,
  setAuthenticated: () => { },
}

const AuthContext = createContext<IAuthContext>(initialValue);

const AuthProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [authenticated, setAuthenticated] = useState<boolean>(false);


  useEffect(() => {
    const init = async () => {
      const response = await dispatch(initialize());
      if (response.meta.requestStatus === "fulfilled") {
        setAuthenticated(true);
      }
    }
    init();
  }, []);


  return (
    <AuthContext.Provider value={{ authenticated, setAuthenticated }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider };
