import { createSlice } from "@reduxjs/toolkit";
import { getAllCompanies, addCompany, deleteCompany, getCompanyById, updateCompany } from "./companiesThunks";
import { CompaniesResponseType, Content } from "./types";


type InitialStateType = {
  allData: CompaniesResponseType | null;
  data: Content | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string;
}


const initialState: InitialStateType = {
  allData: null,
  data: null,
  status: "idle",
  error: "",
}


const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCompanies.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(getAllCompanies.fulfilled, (state, action) => {
      state.allData = action.payload;
      state.status = "succeeded";
    })
    builder.addCase(getAllCompanies.rejected, (state, action) => {
      state.allData = null;
      state.status = "failed";
      state.error = action.error.message || "Firmalar getirilirken bir hata oluştu";
    })

    // GET COMPANY BY ID BUILDER
    builder.addCase(getCompanyById.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(getCompanyById.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = "succeeded";
    })
    builder.addCase(getCompanyById.rejected, (state, action) => {
      state.data = null;
      state.status = "failed";
      state.error = action.error.message || "Firma getirilirken bir hata oluştu";
    })


    // ADD COMPANY BUILDER
    builder.addCase(addCompany.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(addCompany.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = "succeeded";
    })
    builder.addCase(addCompany.rejected, (state, action) => {
      state.data = null;
      state.status = "failed";
      state.error = action.error.message || "Firma eklenirken bir hata oluştu";
    })

    // UPDATE COMPANY BUILDER
    builder.addCase(updateCompany.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = "succeeded";
    })
    builder.addCase(updateCompany.rejected, (state, action) => {
      state.data = null;
      state.status = "failed";
      state.error = action.error.message || "Firma güncellenirken bir hata oluştu";
    })

    // DELETE COMPANY BUILDER
    builder.addCase(deleteCompany.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(deleteCompany.fulfilled, (state, action) => {
      state.status = "succeeded";
    })
    builder.addCase(deleteCompany.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message || "Firma silinirken hata oluştu";
    })
  }
})


export default companiesSlice.reducer;
