import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import FileInputField from '../../../components/formik-fields/file-input-field'
import SelectInputField from '../../../components/formik-fields/select-input-field'
import TextInputField from '../../../components/formik-fields/text-input-field'
import TexInputFieldWithIcon from '../../../components/formik-fields/text-input-field-with-icon'
import SubmitAndCancelButton from '../../../components/submit-cancel-buttons/submit-cancel-button'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { useNavigate, useParams } from 'react-router-dom'
import { PromiseDelay } from '../../../utils/promise-delay'
import { getCompanyById, updateCompany } from '../../../redux/features/companies/companiesThunks'
import { updateComputedPropertyName } from 'typescript'
import { toast } from 'react-toastify'


type initialStateTypes = {
  id: string;
  title: string;
  acUnitPrice: number;
  dcUnitPrice: number;
  contractType: string;
  image: any;
  contract: any;
}

const UpdateCompany = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { status, error } = useAppSelector((state) => state.companies);
  const { id } = useParams();

  const [initialState, setInitialState] = useState<initialStateTypes>({
    id: "",
    title: "",
    acUnitPrice: 0,
    dcUnitPrice: 0,
    contractType: "",
    image: "",
    contract: "",
  });


  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getCompanyById(`${id}`));
      if (response.meta.requestStatus === "fulfilled") {
        setInitialState(response.payload);
      }
    }
    fetchData();
  }, []);


  const handleSubmit = async (values: initialStateTypes) => {
    let jsonData = {
      title: values.title,
      acUnitPrice: values.acUnitPrice,
      dcUnitPrice: values.dcUnitPrice,
      contractType: values.contractType,
    };

    const json = JSON.stringify(jsonData);
    const blob = new Blob([json], { type: "application/json" });

    const formData = new FormData();
    formData.append("request", blob);
    formData.append("imageFile", values.image);
    formData.append("imageFileEventType", "SAVED");
    formData.append("contractFile", values.contract);
    formData.append("contractFileEventType", "SAVED");


    const response = await dispatch(updateCompany({ id: `${id}`, data: formData }));
    if (response.meta.requestStatus === "fulfilled") {
      toast.success("Firma başarılı bir sekilde güncellendi");
      PromiseDelay();
      navigate(-1);
    } else {
      toast.error(error);
    }
  }
  return (
    <div className="rounded-xl bg-white p-5">
      <div className="text-[#666] font-bold text-sm">Firma Bilgileri</div>
      <Formik
        initialValues={initialState}
        onSubmit={(values) => handleSubmit(values)}
        enableReinitialize
      >
        <Form>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 py-5">
            <div>
              <TextInputField
                name="title"
                label="Firma Adı"
                required={true}
                error={false}
              />
            </div>

            <div>
              <Field
                name="image"
                component={FileInputField}
                label="Firma Logosu"
                accepted=".png, .jpg, .jpeg"
                method="PUT"
                required={true}
              />
            </div>

            <div>
              <TexInputFieldWithIcon
                name="acUnitPrice"
                label="AC Birim Fiyat"
                required={true}
                error={false}
              />
            </div>

            <div>
              <TexInputFieldWithIcon
                name="dcUnitPrice"
                label="DC Birim Fiyat"
                required={true}
                error={false}
              />
            </div>

            <div>
              <SelectInputField
                name="contractType"
                label="Anlaşma Durumu"
                required={true}
                error={false}
                items={[
                  { label: "Anlaşmalı", value: "CONTRACTUAL" },
                  { label: "Anlaşmasız", value: "WITHOUT_CONTRACT" },
                  { label: "Beklemede", value: "PENDING" },
                ]}
              />
            </div>

            <div>
              <Field
                name="contract"
                component={FileInputField}
                label="Sözleşme"
                accepted=".pdf"
                method="PUT"
                required={true}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <SubmitAndCancelButton loading={status === "loading" ? true : false} />
          </div>
        </Form>
      </Formik>
    </div>)
}

export default UpdateCompany
